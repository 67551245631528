import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import axios from 'axios';

const QRCodeReader = () => {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const [cameraEnabled, setCameraEnabled] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [qrCodeLocation, setQrCodeLocation] = useState(null); // QR Code 위치 정보를 저장하기 위한 state
    const [userId, setUserId] = useState(''); // User ID를 저장하기 위한 state
    const [showIdInputModal, setShowIdInputModal] = useState(true); // ID 입력을 위한 modal 표시 여부를 관리하는 state

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        let img = new Image();
        img.onload = async function() {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);
            let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            let code;
            try {
                code = jsQR(imageData.data, imageData.width, imageData.height);
            } catch (error) {
                console.error("Failed to decode QR code:", error);
            }
            if (code) {
                setQrCodeLocation(code.location); // QR Code 위치 정보를 state에 저장
                console.log(code.data);
                try {
                    let formData = new FormData();
                    formData.append('url', code.data);
                    formData.append('userId', userId); // User ID 추가
                    const response = await axios.post('/checkuser', formData, 
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.status === 200) {
                        setShowWelcomeModal(true);
                    }
                } catch (error) {
                    console.error("Failed to send request:", error);
                }
            } else {
                setQrCodeLocation(null); // QR Code가 없으면 위치 정보를 null로 설정
            }
        };
        img.src = imageSrc;
    }, [webcamRef, canvasRef, userId]); // useEffect dependency에 userId 추가

    useEffect(() => {
        if (cameraEnabled) {
            const intervalId = setInterval(capture, 1000);
            return () => clearInterval(intervalId);
        }
    }, [cameraEnabled, capture]);

    useEffect(() => {
        if (showWelcomeModal) {
            const timerId = setTimeout(() => setShowWelcomeModal(false), 3000);
            return () => clearTimeout(timerId);
        }
    }, [showWelcomeModal]);

    const handleIdInputSubmit = (event) => {
        event.preventDefault();
        setShowIdInputModal(false); // Submit 시 modal을 닫습니다.
    }

    return (
        <>
        <h1>QR 코드를 가까이 인식해 주세요!</h1>
        {cameraEnabled && (
        <>
            <Webcam
                audio={false}
                width={640}
                height={480}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{
                    transform: "scaleX(-1)", // 좌우 반전
                }}
            />
            <div
                style={{
                    border: '7px solid red', // 빨간색 테두리
                    width: '400px', // 너비
                    height: '400px', // 높이
                    position: 'absolute', // 웹캠 화면 위에 배치
                    left: '50%', // 화면 중앙에 배치
                    top: '57%',
                    transform: 'translate(-50%, -50%)', // 화면 중앙에 정확히 맞춤
                    zIndex: 1, // 웹캠 화면 위에 표시
                }}
            />
            <canvas ref={canvasRef} style={{ display: "none" }} />
        </>
    )}
            {showWelcomeModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={() => setShowWelcomeModal(false)}>
                            &times;
                        </span>
                        <h1>환영합니다!</h1>
                    </div>
                </div>
            )}
            {showIdInputModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h1>아이디를 입력해주세요!</h1>
                        <form onSubmit={handleIdInputSubmit}>
                            <input type="text" value={userId} onChange={(e) => setUserId(e.target.value)} required />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            )}
            <h5> </h5>
            <button onClick={() => setCameraEnabled(!cameraEnabled)}>
                {cameraEnabled ? "QR Scan 종료" : "QR Scan 시작"}
            </button>
        </>
    );
};

export default QRCodeReader;
